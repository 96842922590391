import axios from "axios";
import { returnErrors } from "./errorActions";

import {
	USER_LOADED,
	USER_LOADING,
	AUTH_ERROR,
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	LOGOUT_SUCCESS,
	API_ENDPOINT,
} from "./types";

export const loggedUser = () => async (dispatch, getState) => {
	dispatch({ type: USER_LOADING });
	const getToken = localStorage.getItem("token");
	const config = {
		headers: {
			"Content-type": "application/json",
			Authorization: `Bearer ${getToken}`,
		},
	};
	if (getToken) {
		try {
			const resp = await axios.get(API_ENDPOINT + `/api/profile/`, config);
			console.log(resp)
			if (resp.status === 200) {
				dispatch({
					type: USER_LOADED,
					payload: resp.data,
				});
				return true;
			}
		} catch (err) {
			console.log(err);
			dispatch({
				type: AUTH_ERROR,
			});
			if (!err.response) {
				dispatch(returnErrors("Network Error", 502, "NETWORK_ERROR"));
			} else {
				dispatch(returnErrors(err.response.data.detail, err.response.status));
			}

			return false;
		}
	}
};

export const login = (data) => async (dispatch) => {
	const config = {
		headers: {
			"Content-type": "application/json",
		},
	};
	const body = JSON.stringify(data);
	try {
		const resp = await axios.post(API_ENDPOINT + `/api/token/`, body, config);
		if (resp.status === 200) {
			dispatch({
				type: LOGIN_SUCCESS,
				payload: resp.data,
			});

			return true;
		}
	} catch (err) {
		dispatch({
			type: LOGIN_FAIL,
		});
		if (!err.response) {
			dispatch(returnErrors("Network Error", 502, "NETWORK_ERROR"));
		} else {
			dispatch(
				returnErrors(
					err.response.data.detail,
					err.response.status,
					"LOGIN_FAIL"
				)
			);
		}

		return false;
	}
};

export const logout = () => {
	return {
		type: LOGOUT_SUCCESS,
	};
};
