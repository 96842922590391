import React, { useState } from "react";
import { Navbar, Nav, Modal, Button } from "react-bootstrap";
import Icofont from "react-icofont";
import { withRouter, useHistory } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { logout } from "../../Actions/authActions";
import { connect } from "react-redux";
import eventBus from "../../eventBus";

const Header = ({ location, logout, auth: { user } }) => {
  const [toggleSidebar, setToggleSidebar] = useState(true);
  const [show, setShow] = useState(false);
  const history = useHistory();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function sidebarEnable() {
    setToggleSidebar(!toggleSidebar);
    eventBus.dispatch("toggleSidebar", {
      toggleSidebar: toggleSidebar,
    });
  }

  const logoutAction = () => {
    logout();
    setShow(false);
    history.push("/");
  };
  return (
    <div className="header">
      <Navbar bg="dark" variant="dark">
        <LinkContainer to="/">
          <Navbar.Brand className="ms-3">
            Inventory App
            {location.pathname === "/" ? null : (
              <Icofont icon="navigation-menu" onClick={sidebarEnable} />
            )}
          </Navbar.Brand>
        </LinkContainer>

        {location.pathname === "/" ? null : (
          <Nav className="ms-auto">
            <Nav.Link href="#" className="d-flex align-items-center text-white">
              {user ? user.username : null}
              <div className="avatar-icon ms-3" onClick={handleShow}>
                <Icofont icon="power" />
              </div>
            </Nav.Link>
          </Nav>
        )}
      </Navbar>
      <Modal show={show} onHide={handleClose} keyboard={false} centered>
        <Modal.Header className="border-0 justify-content-center">
          <Modal.Title>Ready to Leave?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <Button className="px-4 me-3" onClick={logoutAction}>
              Logout
            </Button>
            <Button variant="secondary" className="px-4" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  error: state.error,
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(withRouter(Header));
