import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import productReducer from "./productReducer";
import updateQuantityReducer from "./updateQuantityReducer";
import locationReducer from "./locationReducer";
import categoryReducer from "./categoryReducer";

export default combineReducers({
  auth: authReducer,
  error: errorReducer,
  product: productReducer,
  updateQuantity: updateQuantityReducer,
  location: locationReducer,
  category: categoryReducer,
});
