import {
  GET_PRODUCTS,
  PRODUCT_QUANTITY_UPDATED,
  GET_PRODUCTS_FAILED,
  CLEAR_PRODUCTS,
} from "../Actions/types";

const intitalState = {
  products: null,
};

export default function productReducer(state = intitalState, action) {
  switch (action.type) {
    case GET_PRODUCTS:
      return {
        ...state,
        // products: state.products.concat(action.payload),
        products: action.payload,
      };
    case PRODUCT_QUANTITY_UPDATED:
      return {
        ...state,
      };
    case GET_PRODUCTS_FAILED:
    case CLEAR_PRODUCTS:
      return {
        ...state,
        products: null,
      };
    default:
      return state;
  }
}
