import axios from "axios";
import { returnErrors } from "./errorActions";

import {
  API_ENDPOINT,
  GET_PRODUCTS,
  GET_PRODUCTS_FAILED,
  CLEAR_PRODUCTS,
  PRODUCT_QUANTITY_UPDATED,
  PRODUCT_ERROR,
} from "./types";

export const allProducts = () => async (dispatch) => {
  const getToken = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getToken}`,
    },
  };
  try {
    const resp = await axios.get(API_ENDPOINT + `/api/product-list/`, config);
    if (resp.status === 200) {
      dispatch({
        type: GET_PRODUCTS,
        payload: resp.data.results,
      });
      return true;
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: GET_PRODUCTS_FAILED,
    });
    if (!err.response) {
      dispatch(returnErrors("Network Error", 502, "NETWORK_ERROR"));
    } else {
      dispatch(returnErrors(err.response.data.detail, err.response.status));
    }
    return false;
  }
};

export const updateProductQuantity =
  (updateQuantityData) => async (dispatch) => {
    const getToken = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${getToken}`,
      },
    };
    try {
      const resp = await axios.post(
        API_ENDPOINT + `/api/quantity-list/`,
        updateQuantityData,
        config
      );
      if (resp.status === 201) {
        dispatch({
          type: PRODUCT_QUANTITY_UPDATED,
        });
        return true;
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: PRODUCT_ERROR,
      });
      if (!err.response) {
        dispatch(returnErrors("Network Error", 502, "NETWORK_ERROR"));
      } else {
        dispatch(returnErrors(err.response.data.detail, err.response.status));
      }
      return false;
    }
  };

export const clearProducts = () => async (dispatch) => {
  dispatch({
    type: CLEAR_PRODUCTS,
  });
  return true;
};

export const getFilteredProducts = (filteredData) => async (dispatch) => {
  console.log(filteredData);
  const getToken = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getToken}`,
    },
  };
  try {
    const resp = await axios.get(
      API_ENDPOINT +
        `/api/product-list/?quantities__location=${filteredData.byLocation}&category=${filteredData.byCategory}`,
      config
    );
    if (resp.status === 200) {
      console.log(resp.data.results);
      dispatch({
        type: GET_PRODUCTS,
        payload: resp.data.results,
      });
      return true;
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: GET_PRODUCTS_FAILED,
    });
    if (!err.response) {
      dispatch(returnErrors("Network Error", 502, "NETWORK_ERROR"));
    } else {
      dispatch(returnErrors(err.response.data.detail, err.response.status));
    }
    return false;
  }
};
