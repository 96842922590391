import React from "react";
import {} from "react-bootstrap";
import { withRouter } from "react-router-dom";

const Error404 = ({ location }) => {
  return (
    <div className="error404">
      <img src="404.png" alt="error404" className="img-fluid" width="400" />
      <h1 className="text-primary mt-5 display-3">Page not found!</h1>
    </div>
  );
};

export default withRouter(Error404);
