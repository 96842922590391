import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Spinner, Form, Button, Modal } from "react-bootstrap";
import Icofont from "react-icofont";
import ReactDatatable from "@ashvin27/react-datatable";
import { connect } from "react-redux";
import { allProducts, getFilteredProducts, updateProductQuantity } from "../../Actions/productActions";
import { allLocations } from "../../Actions/locationActions";
import { allCategories } from "../../Actions/categoryActions";
import { clearErrors } from "../../Actions/errorActions";
import Select from "react-select";

const Products = ({
	allProducts,
	product: { products },
	allLocations,
	location: { locations },
	allCategories,
	category: { categories },
	getFilteredProducts,
	updateProductQuantity,
	clearErrors,
}) => {
	const [filters, setFilters] = useState({
		byLocation: "",
		byCategory: "",
	});
	const [toggleInput, setToggleInput] = useState(false);
	const [updatedList, setUpdatedList] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [formValues, setFormValues] = useState([{ amount: "", product: "" }]);
	const [show, setShow] = useState(false);

	const handleClose = () => {
		setShow(false);
		setFormValues([{ amount: "", product: "" }]);
	};
	const handleShow = () => setShow(true);

	useEffect(() => {
		// allProducts();
		allLocations();
		allCategories();
	}, []);

	useEffect(() => {
		getFilteredProducts(filters);
	}, [filters]);

	const getLocationValue = (e) => {
		setFilters({
			...filters,
			byLocation: e.target.value,
		});
	};

	const getCategoryValue = (e) => {
		setFilters({
			...filters,
			byCategory: e.target.value,
		});
	};

	const getUpdateQuantityValue = (e) => {
		setUpdatedList({
			...updatedList,
			[e.target.name]: {
				amount: parseInt(e.target.value),
				product: parseInt(e.target.name),
			},
		});
	};

	const submitData = async () => {
		setIsLoading(true);
		var temp = [];
		var jsonData = {
			quantity_list: [],
		};
		for (const i of Object.entries(updatedList)) {
			temp.push(i[1]);
		}
		let u = temp.filter((v) => isNaN(v.amount) != true);
		for (let i = 0; i < u.length; i++) {
			jsonData.quantity_list.push(u[i]);
		}
		const res = await updateProductQuantity(jsonData);
		if (res === true) {
			getFilteredProducts(filters);
			setToggleInput(false);
			setIsLoading(false);
			clearErrors();
		} else {
			setIsLoading(false);
		}
	};

	const columns = [
		{
			key: "location_name",
			text: "Location name",
			sortable: true,
		},
		{
			key: "category",
			text: "Category",
			sortable: true,
			cell: (record) => {
				return <p>{record.category.name}</p>;
			},
		},
		{
			key: "name",
			text: "Name",
			sortable: true,
		},
		{
			key: "latest_quantity",
			text: "Current Quantity",
			sortable: true,
		},
		{
			key: "updated_quantity",
			text: "Updated Quantity",
			sortable: true,
			cell: (record) => {
				return toggleInput ? <Form.Control type="number" placeholder="Enter Quantity" name={record.id} onChange={(e) => getUpdateQuantityValue(e)} /> : null;
			},
		},
	];
	const config = {
		page_size: 10,
		length_menu: [10, 20, 50],
		show_filter: true,
		show_pagination: true,
		pagination: "advanced",
		button: {
			print: true,
		},
		filename: "Inventory Products List",
	};

	const productsList = [];

	if (products != null) {
		for (let i = 0; i < products.length; i++) {
			productsList.push({
				value: products[i].id,
				label: products[i].name,
			});
		}
	}

	const handleChange = (e, i) => {
		let newFormValues = [...formValues];
		newFormValues[i][e.target.name] = e.target.value;
		setFormValues(newFormValues);
	};

	const getProductId = (e, i) => {
		let newFormValues = [...formValues];
		newFormValues[i].product = e.value;
		setFormValues(newFormValues);
	};

	const addFormFields = () => {
		setFormValues([...formValues, { amount: "", product: "" }]);
	};

	const removeFormFields = (i) => {
		let newFormValues = [...formValues];
		newFormValues.splice(i, 1);
		console.log(i);
		console.log(newFormValues)
		setFormValues(newFormValues);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		var newVar = {};
		if (products != null) {
			for (let a = 0; a < products.length; a++) {
				for (let b = 0; b < formValues.length; b++) {
					if (products[a].id === formValues[b].product) {
						newVar = {
							...newVar,
							[formValues[b].product]: {
								product: formValues[b].product,
								amount: parseInt(products[a].latest_quantity) + parseInt(formValues[b].amount),
							},
						};
					}
				}
			}
			console.log(newVar, "newVar");
		}
		setIsLoading(true);
		var temp = [];
		var jsonData = {
			quantity_list: [],
		};
		for (const i of Object.entries(newVar)) {
			temp.push(i[1]);
		}
		let u = temp.filter((v) => isNaN(v.amount) != true);
		for (let i = 0; i < u.length; i++) {
			jsonData.quantity_list.push(u[i]);
		}
		const res = await updateProductQuantity(jsonData);
		if (res === true) {
			getFilteredProducts(filters);
			setToggleInput(false);
			setIsLoading(false);
			setShow(false);
			setFormValues([{ amount: "", product: "" }]);
			clearErrors();
		} else {
			setIsLoading(false);
		}
	};

	return (
		<div className="products">
			<div className="content">
				<Container fluid>
					<Row>
						<Col xl={12}>
							<Card className="mb-4">
								<Card.Header className="bg-primary text-white d-flex align-items-center justify-content-between">
									<span>
										<Icofont icon="settings-alt" /> Manage Products{" "}
									</span>
									<div className="d-flex">
										<Button variant="light" className="me-3" onClick={handleShow}>
											Add/Less Quantity
										</Button>
										{toggleInput ? (
											<div>
												<Button variant="light" onClick={() => setToggleInput(false)} className="me-3">
													Cancel
												</Button>
												<Button variant="light" onClick={() => submitData()} disabled={isLoading ? true : false}>
													{isLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Submit"}
												</Button>
											</div>
										) : (
											<div>
												<Button variant="light" onClick={() => setToggleInput(!toggleInput)}>
													Edit Quanitites
												</Button>
											</div>
										)}
									</div>
								</Card.Header>
								<Card.Body>
									<Row>
										<Col xl={2}>
											<Form className="mb-5">
												<Form.Label>Filter By: Locations</Form.Label>
												<Form.Control as="select" className="form-select" onChange={(e) => getLocationValue(e)} defaultValue="">
													<option value="" disabled>
														Select Location
													</option>
													<option value="">None</option>
													{locations &&
														locations.map((e) => (
															<option value={e.id} key={e.id}>
																{e.name}
															</option>
														))}
												</Form.Control>
											</Form>
										</Col>
										<Col xl={2}>
											<Form className="mb-5">
												<Form.Label>Filter By: Categories</Form.Label>
												<Form.Control as="select" className="form-select" onChange={(e) => getCategoryValue(e)} defaultValue="">
													<option value="" disabled>
														Select Category
													</option>
													<option value="">None</option>
													{categories &&
														categories.map((e) => (
															<option value={e.id} key={e.id}>
																{e.name}
															</option>
														))}
												</Form.Control>
											</Form>
										</Col>
									</Row>
									{products !== null ? (
										<ReactDatatable className="table table-bordered" config={config} columns={columns} records={products} />
									) : (
										<div className="d-flex justify-content-center">
											<Spinner as="span" variant="primary" animation="border" size="lg" role="status" aria-hidden="true" />
										</div>
									)}
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
				<Modal show={show} onHide={handleClose} centered size="lg">
					<Modal.Header closeButton>
						<Modal.Title>Add/Less Quantity</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{formValues.map((x, i) => {
							return (
								<Row key={i} className="mb-3" data-attr={i}>
									<Col xl={6}>
										<Select options={productsList} placeholder="Select Product..." onChange={(e) => getProductId(e, i)} />
									</Col>
									<Col xl={6} className="d-flex">
										<input type="number" className="form-control" placeholder="Enter Value" name="amount" value={x.amount || ""} onChange={(e) => handleChange(e, i)} />
										<Button variant="danger" className="ms-4" onClick={() => removeFormFields(i)}>
											<Icofont icon="close" />
										</Button>
									</Col>
								</Row>
							);
						})}
					</Modal.Body>
					<Modal.Footer>
						<Button variant="success" onClick={() => addFormFields()} disabled={formValues?.length === products?.length ? true : false}>
							<Icofont icon="plus" /> Add More
						</Button>
						<Button variant="primary" onClick={(e) => handleSubmit(e)} disabled={isLoading ? true : false}>
							Submit
						</Button>
					</Modal.Footer>
				</Modal>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	product: state.product,
	location: state.location,
	category: state.category,
});

export default connect(mapStateToProps, {
	allProducts,
	allLocations,
	allCategories,
	getFilteredProducts,
	updateProductQuantity,
	clearErrors,
})(Products);
