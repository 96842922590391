import { GET_CATEGORIES, GET_CATEGORIES_FAILED } from "../Actions/types";

const intitalState = {
  categories: null,
};

export default function categoryReducer(state = intitalState, action) {
  switch (action.type) {
    case GET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case GET_CATEGORIES_FAILED:
      return {
        ...state,
        categories: null,
      };
    default:
      return state;
  }
}
