import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./Components/Layout/Header";
import Footer from "./Components/Layout/Footer";
import Sidebar from "./Components/Layout/Sidebar";
import Login from "./Components/Login";
import Products from "./Components/Products/Products";
import EditProductQuantity from "./Components/UpdateQuantity/EditProductQuantity";
import Error404 from "./Components/Error404";
import eventBus from "./eventBus";
import { Provider } from "react-redux";
import store from "./store";
import PrivateRoute from "./Components/PrivateRoute";
import UpdateQuantity from "./Components/UpdateQuantity/UpdateQuantity";
import { loggedUser } from "./Actions/authActions";

function App() {
  const [sidebarToggled, setSidebarToggled] = useState(false);

  useEffect(() => {
    store.dispatch(loggedUser());
    eventBus.on("toggleSidebar", (data) =>
      setSidebarToggled(data.toggleSidebar)
    );
    return () => {
      eventBus.remove("toggleSidebar");
    };
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <div className={sidebarToggled ? "App toggled" : "App"}>
          <Header />
          <Route exact path="/">
            <Login />
          </Route>
          <div className="d-flex">
            <Sidebar />
            <Switch>
              <PrivateRoute
                exact
                path="/404"
                component={Error404}
              ></PrivateRoute>
              <PrivateRoute
                exact
                path="/products"
                component={Products}
              ></PrivateRoute>
              <PrivateRoute
                exact
                path="/products/edit-product-quantity/:productId/:locationId"
                component={EditProductQuantity}
              ></PrivateRoute>
              <PrivateRoute
                exact
                path="/logs"
                component={UpdateQuantity}
              ></PrivateRoute>
              {/* <PrivateRoute render={() => <Error404 />} /> */}
            </Switch>
          </div>
          <Footer />
        </div>
      </Router>
    </Provider>
  );
}

export default App;
