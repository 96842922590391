import { GET_LOCATIONS, GET_LOCATIONS_FAILED } from "../Actions/types";

const intitalState = {
  locations: null,
};

export default function locationReducer(state = intitalState, action) {
  switch (action.type) {
    case GET_LOCATIONS:
      return {
        ...state,
        locations: action.payload,
      };
    case GET_LOCATIONS_FAILED:
      return {
        ...state,
        locations: null,
      };
    default:
      return state;
  }
}
