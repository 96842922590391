import {
	USER_LOADED,
	USER_LOADING,
	AUTH_ERROR,
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	LOGOUT_SUCCESS,
} from "../Actions/types";

const intitalState = {
	token: localStorage.getItem("token"),
	isAuthenticated: false,
	isLoading: false,
	user: null,
};

export default function authReducer(state = intitalState, action) {
	switch (action.type) {
		case USER_LOADING:
			return {
				...state,
				isLoading: true,
			};
		case USER_LOADED:
			return {
				...state,
				isAuthenticated: true,
				isLoading: false,
				user: action.payload,
			};
		case LOGIN_SUCCESS:
			localStorage.setItem("token", action.payload.access);
			localStorage.setItem("refresh_token", action.payload.refresh);
			return {
				...state,
				token: action.payload.access,
				isAuthenticated: true,
				isLoading: false,
			};
		case AUTH_ERROR:
		case LOGIN_FAIL:
		case LOGOUT_SUCCESS:
			localStorage.removeItem("token");
			localStorage.removeItem("refresh_token");
			return {
				...state,
				token: null,
				user: null,
				isAuthenticated: false,
				isLoading: false,
			};
		default:
			return state;
	}
}
