export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const PRODUCT_QUANTITY_UPDATED = "PRODUCT_QUANTITY_UPDATED";
export const PRODUCT_ERROR = "PRODUCT_ERROR";

export const UPDATE_QUANTITY_SUCCESS = "UPDATE_QUANTITY_SUCCESS";
export const UPDATE_QUANTITY_FAILED = "UPDATE_QUANTITY_FAILED";

export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_PRODUCTS_FAILED = "GET_PRODUCTS_FAILED";
export const CLEAR_PRODUCTS = "CLEAR_PRODUCTS";

export const GET_LOCATIONS = "GET_LOCATIONS";
export const GET_LOCATIONS_FAILED = "GET_LOCATIONS_FAILED";

export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_CATEGORIES_FAILED = "GET_CATEGORIES_FAILED";

export const API_ENDPOINT = "https://inventory-management.daf4i4kxqn.co.uk";
