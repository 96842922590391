import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./Reducers";

const intitalState = {};

const store = createStore(
  rootReducer,
  intitalState,
  compose(
    applyMiddleware(thunk)
  )
);

export default store;
