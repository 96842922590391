import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import {} from "react-bootstrap";
import Icofont from "react-icofont";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

const Sidebar = ({ location }) => {
  return (
    <Fragment>
      {location.pathname === "/" ? null : (
        <div>
          <ul
            className={
              window.location.pathname === "/pos"
                ? "sidebar toggled navbar-nav"
                : "sidebar navbar-nav"
            }
          >
            <li className="nav-item">
              <NavLink to="/products" className="nav-link">
                <Icofont icon="box" />
                <span>Products</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/logs" className="nav-link">
                <Icofont icon="box" />
                <span>Logs</span>
              </NavLink>
            </li>
          </ul>
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  error: state.error,
  auth: state.auth,
});

export default connect(mapStateToProps)(withRouter(Sidebar));
