import {
  UPDATE_QUANTITY_SUCCESS,
  UPDATE_QUANTITY_FAILED,
} from "../Actions/types";

const intitalState = {
  product_update_quantity: [],
};

export default function updateQuantityReducer(state = intitalState, action) {
  switch (action.type) {
    case UPDATE_QUANTITY_SUCCESS:
      return {
        ...state,
        // product_update_quantity: state.product_update_quantity.concat(
        //   action.payload
        // ),
        product_update_quantity: action.payload,
      };
    case UPDATE_QUANTITY_FAILED:
      return {
        ...state,
        product_update_quantity: [],
      };
    default:
      return state;
  }
}
