import React, { useEffect } from "react";
import { Container, Row, Col, Card, Spinner } from "react-bootstrap";
import Icofont from "react-icofont";
import ReactDatatable from "@ashvin27/react-datatable";
import Moment from "react-moment";
import { connect } from "react-redux";
import { allProducts } from "../../Actions/productActions";
import { getUpdateQuantity } from "../../Actions/updateQuantityActions";
import { allLocations } from "../../Actions/locationActions";

const UpdateQuantity = ({
  allProducts,
  getUpdateQuantity,
  product: { products },
  updateQuantity: { product_update_quantity },
  allLocations,
  location: { locations },
}) => {
  useEffect(() => {
    allProducts();
    allLocations();
  }, []);

  useEffect(() => {
    if (products !== null && locations !== null) {
      getUpdateQuantity(products, locations);
    }
  }, [products, locations]);

  const columns = [
    {
      key: "location_name",
      text: "Location name",
      sortable: true,
    },
    {
      key: "product_name",
      text: "Product Name",
      sortable: true,
    },
    {
      key: "product_category",
      text: "Product Category",
      sortable: true,
    },
    {
      key: "amount",
      text: "Updated Quantity",
      sortable: true,
    },
    {
      key: "updated",
      text: "Date Updated",
      sortable: true,
      cell: (record, index) => {
        return (
          <div key={index}>
            <Moment date={record.updated} format="MMMM Do YYYY, h:mm:ss a" />
          </div>
        );
      },
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_filter: true,
    show_pagination: true,
    pagination: "advanced",
    sort: {
      column: "updated",
      order: "desc",
    },
  };
  return (
    <div className="products">
      <div className="content">
        <Container fluid>
          <Row>
            <Col xl={12}>
              <Card className="mb-4">
                <Card.Header className="bg-primary text-white d-flex align-items-center justify-content-between">
                  <span>
                    <Icofont icon="settings-alt" /> Logs
                  </span>
                </Card.Header>
                <Card.Body>
                  {product_update_quantity !== null ? (
                    <ReactDatatable
                      className="table table-bordered"
                      config={config}
                      columns={columns}
                      records={product_update_quantity}
                    />
                  ) : (
                    <div className="d-flex justify-content-center">
                      <Spinner
                        as="span"
                        variant="primary"
                        animation="border"
                        size="lg"
                        role="status"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  product: state.product,
  updateQuantity: state.updateQuantity,
  location: state.location,
});

export default connect(mapStateToProps, {
  allProducts,
  allLocations,
  getUpdateQuantity,
})(UpdateQuantity);
