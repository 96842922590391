import axios from "axios";
import { returnErrors } from "./errorActions";

import {
  UPDATE_QUANTITY_SUCCESS,
  UPDATE_QUANTITY_FAILED,
  API_ENDPOINT,
} from "./types";

export const getUpdateQuantity = (products, locations) => async (dispatch) => {
  const getToken = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getToken}`,
    },
  };
  try {
    let quantityData = [];
    let getData = await Promise.all(
      products.map(async (e) => {
        let filterr = locations.filter((i) => i.name === e.location_name);
        let resp = await axios.get(
          API_ENDPOINT +
            `/api/quantity-list/?product=${e.id}&location=${filterr[0].id}`,
          config
        );
        resp.data.results.map((item) => {
          quantityData.push({
            ...item,
            location_name: e.location_name,
            product_name: e.name,
            product_category: e.category.name,
          });
          return true;
        });
        return true;
      })
    );
    if (getData.at(-1)) {
      dispatch({
        type: UPDATE_QUANTITY_SUCCESS,
        payload: quantityData,
      });
      return true;
    }
  } catch (err) {
    dispatch({
      type: UPDATE_QUANTITY_FAILED,
    });
    if (!err.response) {
      dispatch(returnErrors("Network Error", 502, "NETWORK_ERROR"));
    } else {
      dispatch(returnErrors(err.response.data, err.response.status));
    }
    return false;
  }
};
