import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import Icofont from "react-icofont";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import {
  allProducts,
  clearProducts,
  updateProductQuantity,
} from "../../Actions/productActions";
import { clearErrors } from "../../Actions/errorActions";

const EditProduct = ({
  allProducts,
  clearErrors,
  error,
  product: { products },
  updateProductQuantity,
  match,
}) => {
  const [isProduct, setIsProduct] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    amount: "",
    product: "",
    product_name: "",
    location_name: "",
    category: "",
  });
  const [errorMsg, setErrorMsg] = useState(null);
  const history = useHistory();

  useEffect(() => {
    setErrorMsg(error.msg);
  }, [error]);

  useEffect(() => {
    allProducts();
  }, []);

  useEffect(() => {
    if (products && products.length !== 0) {
      const x = products.filter(
        (v) =>
          v.id === parseInt(match.params.productId) &&
          v.location_id === parseInt(match.params.locationId)
      );
      setData({
        amount: x[0].latest_quantity,
        product: x[0].id,
        product_name: x[0].name,
        location_name: x[0].location_name,
        category: x[0].category.name,
      });
      if (
        x.length !== 0 &&
        x[0].id === parseInt(match.params.productId) &&
        x[0].location_id === parseInt(match.params.locationId)
      ) {
        setIsProduct(true);
      } else {
        history.push("/404");
      }
    }
  }, [products]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    let updateQuantityData = {
      amount: data.amount,
      product: data.product,
    };
    const res = await updateProductQuantity(updateQuantityData);
    if (res === true) {
      history.push("/products-quantity");
      setIsLoading(false);
      clearErrors();
    } else {
      setIsLoading(false);
    }
  };

  return (
    <div className="products">
      {isProduct ? (
        <div className="content">
          <Container fluid>
            <Row>
              <Col xl={12}>
                <Card className="mb-4">
                  <Card.Header className="bg-primary text-white">
                    <Icofont icon="plus" /> Edit Product
                  </Card.Header>
                  <Card.Body>
                    {errorMsg ? (
                      <p className="text-danger">{errorMsg}</p>
                    ) : null}
                    <Form onSubmit={handleSubmit}>
                      <Form.Row>
                        <Form.Group as={Col}>
                          <Form.Label>Location Name</Form.Label>
                          <Form.Control
                            type="text"
                            required
                            name="location_name"
                            defaultValue={data.location_name}
                            disabled
                          />
                        </Form.Group>
                        <Form.Group as={Col}>
                          <Form.Label>Product Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Product Name"
                            required
                            name="product_name"
                            defaultValue={data.product_name}
                            disabled
                          />
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col}>
                          <Form.Label>Category</Form.Label>
                          <Form.Control
                            type="text"
                            defaultValue={data.category}
                            required
                            name="category"
                            disabled
                          />
                        </Form.Group>
                        <Form.Group as={Col}>
                          <Form.Label>Update Quantity</Form.Label>
                          <Form.Control
                            type="number"
                            defaultValue={data.amount}
                            required
                            name="amount"
                            onChange={(e) =>
                              setData({
                                ...data,
                                [e.target.name]: e.target.value,
                              })
                            }
                          />
                        </Form.Group>
                      </Form.Row>
                      <Button variant="primary" type="submit">
                        {isLoading ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  product: state.product,
  error: state.error,
});

export default connect(mapStateToProps, {
  clearProducts,
  allProducts,
  clearErrors,
  updateProductQuantity,
})(EditProduct);
