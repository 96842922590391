import axios from "axios";
import { returnErrors } from "./errorActions";

import { API_ENDPOINT, GET_CATEGORIES, GET_CATEGORIES_FAILED } from "./types";

export const allCategories = () => async (dispatch) => {
  const getToken = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getToken}`,
    },
  };
  try {
    const resp = await axios.get(API_ENDPOINT + `/api/category-list/`, config);
    if (resp.status === 200) {
      dispatch({
        type: GET_CATEGORIES,
        payload: resp.data,
      });
      return true;
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: GET_CATEGORIES_FAILED,
    });
    if (!err.response) {
      dispatch(returnErrors("Network Error", 502, "NETWORK_ERROR"));
    } else {
      dispatch(returnErrors(err.response.data.detail, err.response.status));
    }
    return false;
  }
};
