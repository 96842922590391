import React, { useState, useEffect } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { useHistory } from "react-router";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { login, loggedUser } from "../Actions/authActions";
import { clearErrors } from "../Actions/errorActions";

const Login = ({ login, clearErrors, error, loggedUser }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    username: "",
    password: "",
  });
  const [errorMsg, setErrorMsg] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (error.msg) {
      setErrorMsg(error.msg);
    }
  }, [error]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const res = await login(data);
    if (res === true) {
      loggedUser();
      history.push("/products");
      setIsLoading(false);
      clearErrors();
    } else {
      setData({ username: "", password: "" });
      setIsLoading(false);
    }
  };
  if (localStorage.getItem("token")) {
    return <Redirect to="/products" />;
  }
  return (
    <div className="login">
      <Form onSubmit={handleSubmit}>
        <h2>Login</h2>
        {errorMsg !== null ? <p className="text-danger">{errorMsg}</p> : null}
        <Form.Group controlId="username" className="mb-3">
          <Form.Label>Username</Form.Label>
          <Form.Control
            type="text"
            placeholder="Username"
            required
            name="username"
            value={data.username}
            onChange={(e) =>
              setData({
                ...data,
                [e.target.name]: e.target.value,
              })
            }
          />
        </Form.Group>
        <Form.Group controlId="password" className="mb-3">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            required
            name="password"
            value={data.password}
            onChange={(e) =>
              setData({
                ...data,
                [e.target.name]: e.target.value,
              })
            }
          />
        </Form.Group>
        <div className="d-flex align-items-center justify-content-center mt-4">
          <Button
            type="submit"
            variant="success"
            className="w-50 d-flex align-items-center justify-content-center"
          >
            {isLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Login"
            )}
          </Button>
        </div>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  error: state.error,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  login,
  clearErrors,
  loggedUser,
})(withRouter(Login));
