import React from "react";
import {} from "react-bootstrap";
import { withRouter } from "react-router-dom";

const Footer = ({ location }) => {
  return (
    <div
      className={
        location.pathname === "/"
          ? "footer fullWidth bg-light"
          : "footer bg-light"
      }
    >
      <p>Copyright &copy; {new Date().getFullYear()}</p>
    </div>
  );
};

export default withRouter(Footer);
